import { createI18n } from "vue-i18n";
import { getHostname } from "./utils/hostname";
import { Quasar } from "quasar";
import { LOCALES } from "./constants";

export const getHostMessages = async () => {
  const hostName = getHostname();
  const defaultMessages = {};
  const hostMessages = {};

  // Always load default translations and if host is not default, load additional translations
  for (const locale of LOCALES) {
    defaultMessages[locale] = (await import(`./i18n/hosts/default/${locale}/index.js`)).default;

    try {
      hostMessages[locale] = (await import(`./i18n/hosts/${hostName}/${locale}/index.js`)).default;
    } catch (e) {
      console.warn(`No translation file found for host: ${hostName} and locale: ${locale}`);
    }
  }

  // If host messages exist, replace messages variables
  const messages = {};
  for (const locale of LOCALES) {
    messages[locale] = {
      ...defaultMessages[locale],
      ...hostMessages[locale],
    };
  }

  return messages;
};

const i18n = createI18n({
  locale: LOCALES.includes(Quasar.lang.getLocale()) ? Quasar.lang.getLocale() : "en-US",
  fallbackLocale: "en-US",
  globalInjection: true,
  legacy: false,
  messages: await getHostMessages(),
});

export default i18n;

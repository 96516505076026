import { getHostname } from "../utils/hostname";
import type { Plugin } from "vue";

const HostnamePlugin: Plugin = {
  install: (app) => {
    app.provide("getHostname", getHostname);
  },
};

export default HostnamePlugin;

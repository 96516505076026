import { ActionTree, GetterTree, MutationTree } from "vuex";
import { get, post, Delete, put } from "../services/api";
import { rootState } from "../types/store/state";
import { DataPlan, DataPlanPrimaryKey, SigmaDevice, UpdateDataPlanPayload } from "../types/store/sigma";

export interface SigmaModule {
  namespaced: boolean;
  state: () => SigmaState;
  getters: GetterTree<SigmaState, rootState>;
  actions: ActionTree<SigmaState, rootState>;
  mutations: MutationTree<SigmaState>;
}

export interface SigmaState {
  dataPlanLoading: boolean;
  dataPlans: DataPlan[];
  sigmaDevices: SigmaDevice[];
}

const sigma_module: SigmaModule = {
  namespaced: true,
  state: () => ({
    dataPlans: [],
    dataPlanLoading: false,
    sigmaDevices: [],
  }),
  getters: {
    dataPlans: (state) => {
      return state.dataPlans;
    },
    dataPlanLoading: (state) => {
      return state.dataPlanLoading;
    },
    getFirstDeviceId: (state) => {
      return state.sigmaDevices[0]?.sigmas[0]?.sys_id;
    },
    getFirstServiceId: (state) => {
      return state.sigmaDevices[0]?.sys_id;
    },
    getBusinessServiceByDeviceId: (state) => (params: { businessServiceId: string; deviceId: string }) => {
      const businessService = state.sigmaDevices.find((bs) => bs.sys_id === params.businessServiceId);

      if (!businessService) {
        return;
      }

      const device = businessService.sigmas.find((sigma) => sigma.sys_id === params.deviceId);

      return { businessService, device };
    },
    sigmaDevices: (state) => {
      return state.sigmaDevices;
    },
  },
  actions: {
    addDataPlan({ commit }, { companySysId, businessServiceSysId, deviceSysId, ...payload }: DataPlan) {
      return post(`tdapi/sigma/${companySysId}/${businessServiceSysId}/${deviceSysId}/data-plans`, payload).then(
        (response) => {
          commit("updateDataPlans", response);
          return null;
        }
      );
    },
    deleteDataPlan({ commit }, { companySysId, businessServiceSysId, deviceSysId }: DataPlanPrimaryKey) {
      return Delete(`tdapi/sigma/${companySysId}/${businessServiceSysId}/${deviceSysId}/data-plans`).then(() => {
        commit("updateDataPlans", []);
        return null;
      });
    },
    getDataPlans({ commit }, { companySysId, businessServiceSysId, deviceSysId }: DataPlanPrimaryKey) {
      return get(`tdapi/sigma/${companySysId}/${businessServiceSysId}/${deviceSysId}/data-plans`).then((response) => {
        const dataPlans = response.data;
        commit("updateDataPlans", dataPlans);

        return dataPlans;
      });
    },
    getSigmasApi({ commit }, { companyId }: { companyId: string }) {
      return get(`tdapi/sigma/${companyId}`).then((response) => {
        commit("updateSigmasDevices", response.data);
      });
    },
    updateDataPlan({ commit }, { companySysId, businessServiceSysId, deviceSysId, ...payload }: UpdateDataPlanPayload) {
      put(`tdapi/sigma/${companySysId}/${businessServiceSysId}/${deviceSysId}/data-plans`, payload).then((response) => {
        commit("updateDataPlans", response);
        return null;
      });
    },
  },
  mutations: {
    deleteDataPlans: (state, data) => {
      const indexToDelete = state.dataPlans.findIndex(
        (dataPlan) =>
          dataPlan.businessServiceSysId === data.businessServiceSysId &&
          dataPlan.companySysId === data.companySysId &&
          dataPlan.deviceSysId === dataPlan.deviceSysId
      );
      state.dataPlans.splice(indexToDelete, 1);
    },
    updateDataPlans: (state, data) => {
      state.dataPlans = data;
    },
    updateSigmasDevices: (state, data) => {
      state.sigmaDevices = data;
    },
  },
};

export default sigma_module;

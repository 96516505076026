import { getObject, setObject } from "./store_helpers";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import { rootState } from "../types/store/state";
import { geoJson, Beams } from "../types/store/cnoVno";

export interface CnoVnoModule {
  namespaced: boolean;
  state: () => CnoVnoState;
  getters: GetterTree<CnoVnoState, rootState>;
  actions: ActionTree<CnoVnoState, rootState>;
  mutations: MutationTree<CnoVnoState>;
}

export interface CnoVnoState {
  beams: Beams | null;
  satellites: geoJson | null;
  teleports: geoJson | null;
}

const cno_vno_module: CnoVnoModule = {
  namespaced: true,
  state: () => ({
    beams: null,
    satellites: null,
    teleports: null,
  }),
  getters: {
    beams: (state) => {
      return state.beams;
    },
    satellites: (state) => {
      return state.satellites;
    },
    teleports: (state) => {
      return state.teleports;
    },
  },
  actions: {},
  mutations: {
    dataFromLocalStorage: (state) => {
      let beams = getObject<Beams>("beams");
      if (beams) {
        state.beams = beams;
      }
      let satellites = getObject<geoJson>("satellites");
      if (satellites) {
        state.satellites = satellites;
      }
      let teleports = getObject<geoJson>("teleports");
      if (teleports) {
        state.teleports = teleports;
      }
    },
    updateBeams: (state, data) => {
      state.beams = data;
      setObject("beams", state.beams);
    },
    updateSatellites: (state, data) => {
      state.satellites = data;
      setObject("satellites", state.satellites);
    },
    updateTeleports: (state, data) => {
      state.teleports = data;
      setObject("teleports", state.teleports);
    },
  },
};

export default cno_vno_module;

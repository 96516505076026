import type { Plugin } from "vue";

type CloudWatchRumEvent = {
  type: string;
  data: Record<string, unknown>;
};

export type CloudWatchRumFn = {
  // See: https://github.com/aws-observability/aws-rum-web/blob/main/docs/cdn_commands.md#commands
  (command: "allowCookies", param: boolean): void;
  (command: "disable"): void;
  (command: "dispatch"): void;
  (command: "dispatchBeacon"): void;
  (command: "enable"): void;
  (command: "addSessionAttributes", param: MetadataAttributes): void;
  (command: "recordPageView", param: String | PageView): void;
  (command: "recordError", param: Error | ErrorEvent | string): void;
  (command: "recordEvent", param: CloudWatchRumEvent): void;
  (command: "registerDomEvents", param: Array<unknown>): void;
  (command: "setAwsCredentials", param: unknown /* Credentials | CredentialProvider */): void;
};

type MetadataAttributes = Record<string, string>;

export type PageView = {
  pageId: string;
  pageTags?: string[];
  pageAttributes?: Record<string, string | number | boolean>;
};

declare global {
  interface Window {
    cwr: unknown;
  }
}

const CloudWatchRumPlugin: Plugin = {
  install: (app) => {
    const cloudWatchRum = (window.cwr ??
      ((...args) => {
        console.warn("cloudWatchRum fallback used", args);
      })) as CloudWatchRumFn;
    app.config.globalProperties.cloudWatchRum = cloudWatchRum;
    app.provide("cloudWatchRum", cloudWatchRum);
  },
};

export default CloudWatchRumPlugin;

export const STARLINK_ALLOWED_COUNTRIES = [
  "Speedcast (BR)",
  "Speedcast (CY)",
  "Speedcast (DE)",
  "Speedcast (FR)",
  "Speedcast (GB)",
  "Speedcast (MX)",
  "Speedcast (NL)",
  "Speedcast (US)",
  "Speedcast Mobility (US)",
];

export const SUPPORT_GROUPS = {
  "Ops-CSC-Lima": "f9d6b12fdb83c700be7d5a48dc961949",
  "Ops-Energy-EEM-NOC": "2e9bf6fb1b317190f8b2dc66bd4bcb3b",
};

export const STARLINK_KIT_NAME_MAX_CHAR_COUNT = 50;

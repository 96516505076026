import { ActionTree, GetterTree, MutationTree } from "vuex";
import { get } from "../services/api";
import { setObject } from "./store_helpers";
import { rootState } from "../types/store/state";

export interface Aggregates {
  priority: {
    "2 - High"?: string;
    "3 - Moderate"?: string;
  };
  state: {
    Implement?: string;
    Scheduled?: string;
  };
}

export interface ChangeRequestsData {
  assignment_group: string;
  attachments: string[];
  business_service: string;
  business_service_sys_id: string;
  comments: Comment[];
  company: string;
  company_sys_id: string;
  created: string;
  createdby: string;
  description: string;
  end_date: string;
  number: string;
  priority: string;
  reason: string;
  service: string;
  short_description: string;
  start_date: string;
  state: string;
  sys_id: string;
  sys_updated_on: string;
  updated: string;
}

export interface ChangeRequestModule {
  namespaced: boolean;
  state: () => ChangeRequestsState;
  getters: GetterTree<ChangeRequestsState, rootState>;
  actions: ActionTree<ChangeRequestsState, rootState>;
  mutations: MutationTree<ChangeRequestsState>;
}

export interface ChangeRequestsState {
  changeRequests: ChangeRequestsData[];
  filteredChangeRequests: ChangeRequestsData[];
  aggregates: Aggregates;
  filteredAggregates: Aggregates;
  changeRequestsLoading: boolean;
  filters: {
    [key: string]: any;
    state: string;
    priority: string;
    service: string;
    search: string;
    notState: string[];
  };
  filterLocally: boolean;
  showClosed: boolean;
  showAll: boolean;
  setPriorityAgg: boolean;
  setStateAgg: boolean;
}

export interface Comment {
  comment: string;
  name: string;
  timestamp: string;
}

const change_requests_module: ChangeRequestModule = {
  namespaced: true,
  state: () => ({
    changeRequests: [],
    filteredChangeRequests: [],
    aggregates: {
      priority: {},
      state: {},
    },
    filteredAggregates: {
      priority: {},
      state: {},
    },
    changeRequestsLoading: false,
    filters: {
      state: "",
      priority: "",
      service: "",
      search: "",
      notState: [],
    },
    filterLocally: false,
    showClosed: false,
    showAll: true,
    setPriorityAgg: false,
    setStateAgg: false,
  }),

  getters: {
    changeRequests: (state) => {
      return state.changeRequests;
    },
    filteredChangeRequests: (state) => {
      return state.filteredChangeRequests;
    },
    aggregates: (state) => {
      return state.filteredAggregates;
    },
    changeRequest: (state) => (number: string) => {
      return state.changeRequests.find((t) => t.number == number);
    },
    changeRequestsLoading: (state) => {
      return state.changeRequestsLoading;
    },
    filters: (state) => {
      return state.filters;
    },
  },
  actions: {
    getChangeRequests({ commit }, companyid) {
      commit("updateChangeRequestsStart");
      return get(`tdapi/changerequests/${companyid}`).then((data) => {
        if (data.data) {
          commit("setChangeRequests", data.data);
          commit("setFilteredChangeRequests", data.data);
        }
        commit("updateChangeRequestsStop");
      });
    },
    getAggregates({ commit }, company) {
      get(`tdapi/changerequestaggregates/${company}`).then(({ data }) => {
        commit("setAggregates", data);
        commit("setFilteredAggregates", data);
      });
    },
    async filterChangeRequests({ commit, dispatch, state }, filters) {
      let recount = filters.search != undefined && filters.search != state.filters.search;
      commit("setFilters", filters);
      // use cached original request if filters are removed
      if (state.showAll) {
        commit("setFilteredChangeRequests", state.changeRequests);
        commit("setFilteredAggregates", state.aggregates);
      } else {
        // try to filter on client before using api call
        if (state.filterLocally) {
          let filteredChangeRequests = state.filteredChangeRequests
            .filter((req) => {
              if (state.filters.state && req.state != state.filters.state) return false;
              if (state.filters.priority && req.priority != state.filters.priority) return false;
              if (state.filters.service && req.service != state.filters.service) return false;
              if (state.filters.notState.length > 0 && state.filters.notState.includes(req.state)) return false;
              if (state.filters.search) {
                return JSON.stringify(req).toLowerCase().includes(state.filters.search.toLowerCase());
              }
              return true;
            })
            .sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());
          commit("setFilteredChangeRequests", filteredChangeRequests);
          if (recount) {
            dispatch("countAggregates");
          }
        } else {
          let queryStr = `?q=${state.filters.search || ""}&state=${state.filters.state || ""}&priority=${
            state.filters.priority || ""
          }&business_service=${state.filters.service || ""}&notState=${state.filters.notState.join(",")}`;
          dispatch("fetchFilteredChangeRequests", queryStr);
          dispatch("fetchFilteredAggregates", queryStr);
        }
      }
    },
    fetchFilteredChangeRequests({ commit, rootState }, payload) {
      commit("updateChangeRequestsStart");
      let company_id = rootState.company?.company_id;
      return get(`tdapi/changerequests/${company_id}/search${payload}`).then(({ data }) => {
        commit("setFilteredChangeRequests", data);
        commit("updateChangeRequestsStop");
      });
    },
    fetchFilteredAggregates({ commit, rootState }, payload) {
      let company_id = rootState.company?.company_id;
      get(`tdapi/changerequestaggregates/${company_id}${payload}`).then(({ data }) => {
        commit("setFilteredAggregates", data);
      });
    },
    countAggregates({ commit, state }) {
      let aggs: { [key: string]: number } = {};
      state.filteredChangeRequests.forEach((req) => {
        if (aggs[req.state]) {
          aggs[req.state]++;
        } else {
          aggs[req.state] = 1;
        }
      });
      commit("setFilteredAggregates", aggs);
    },
    toggleVisibility({ dispatch, state }, newState) {
      let filters: { notState: string[] } = { notState: [] };
      if (newState.visibility) {
        filters.notState = state.filters.notState.filter((s) => s !== newState.state);
      } else {
        filters.notState = state.filters.notState.concat(newState.state);
      }
      dispatch("filterChangeRequests", filters);
    },
    clearChangeRequests({ commit }) {
      commit("nullChangeRequests");
    },
  },
  mutations: {
    updateChangeRequestsStart: (state) => {
      state.changeRequestsLoading = true;
    },
    updateChangeRequestsStop: (state) => {
      state.changeRequestsLoading = false;
    },
    setChangeRequests: (state, changeRequests) => {
      state.changeRequests = changeRequests;
      state.filterLocally = changeRequests.length < 100;
      setObject("changeRequests", changeRequests);
    },
    setFilteredChangeRequests: (state, changeRequests) => {
      state.filterLocally = changeRequests.length < 100;
      state.filteredChangeRequests = changeRequests;
    },
    setAggregates: (state, aggs) => {
      state.aggregates = aggs;
    },
    setFilteredAggregates: (state, aggs) => {
      // don't set agg values for active filter so donut still shows slices instead of solid color
      if (!state.filters.priority || state.setPriorityAgg) {
        state.filteredAggregates.priority = aggs.priority;
      }
      if (!state.filters.state || state.setStateAgg) {
        state.filteredAggregates.state = aggs.state;
      }
      state.setPriorityAgg = false;
      state.setStateAgg = false;
    },
    setFilters: (state, filters) => {
      for (let f in filters) {
        //compare filters to ensure fewer filters(more data) calls api
        if (state.filters[f] && filters[f] !== state.filters[f]) {
          state.filterLocally = false;
        }
        //logic for telling pie charts to redraw
        if (f == "business_service" || f == "search") {
          state.setPriorityAgg = true;
          state.setStateAgg = true;
        }
        state.filters[f] = filters[f];
      }
      state.showAll = Object.values(state.filters).filter((f) => f).length == 0;
    },
    nullChangeRequests: (state) => {
      state.changeRequests = [];
      localStorage.removeItem("changeRequests");
    },
    updateChangeRequest: (state, changeRequest) => {
      let foundChangeRequest = state.changeRequests.find((t) => t.number == changeRequest.number);
      if (foundChangeRequest) {
        foundChangeRequest = changeRequest;
      }
    },
  },
};
export default change_requests_module;

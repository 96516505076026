import store from "../store";
import { get } from "./api";

export { netmaskToCidr, downloadCSVData, PROTOCOLS, MODCODS, KMG };

const netmaskToCidr = (n) => n.split(".").reduce((c, o) => c - Math.log2(256 - +o), 32);

// Returns value in best metric-fit measure: Kilo/Mega/Giga/Tera
const KMG = (y) => {
  if (y >= 1000000000000) {
    return Math.round(y / 100000000000) / 10 + "T";
  }
  if (y >= 1000000000) {
    return Math.round(y / 100000000) / 10 + "G";
  }
  if (y >= 1000000) {
    return Math.round(y / 100000) / 10 + "M";
  }
  if (y >= 1000) {
    return Math.round(y / 100) / 10 + "k";
  }
  return Math.round(y * 10) / 10;
};

const downloadCSVData = (data, name) => {
  let csv = "";
  Object.keys(data[0]).forEach((t) => {
    if (csv != "") {
      csv += ",";
    }
    csv += `"${t}"`;
  });
  csv += "\n";

  data.forEach((row) => {
    var rowstart = true;
    Object.keys(data[0]).forEach((t) => {
      if (!rowstart) {
        csv += ",";
      }
      rowstart = false;

      csv += `"${row[t]}"`;
    });
    csv += "\n";
  });

  const anchor = document.createElement("a");
  anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
  anchor.target = "_blank";

  anchor.download = `${name} ${new Date().toISOString().substring(0, 18)}.csv`;
  anchor.click();
};

const MODCODS = [
  "QPSK_1/4",
  "QPSK_1/3",
  "QPSK_2/5",
  "QPSK_1/2",
  "QPSK_3/5",
  "QPSK_2/3",
  "QPSK_3/4",
  "QPSK_4/5",
  "QPSK_5/6",
  "QPSK_8/9",
  "QPSK_9/10",
  "8PSK_3/5",
  "8PSK_2/3",
  "8PSK_3/4",
  "8PSK_5/6",
  "8PSK_8/9",
  "8PSK_9/10",
  "16PSK_2/3",
  "16PSK_3/4",
  "16PSK_4/5",
  "16PSK_5/6",
  "16PSK_8/9",
  "16PSK_9/10",
  "32PSK_3/4",
  "32PSK_4/5",
  "32PSK_5/6",
  "32PSK_8/9",
  "32PSK_9/10",
];
const PROTOCOLS = {
  0: "",
  1: "ICMP",
  10: "BBN-RCC-MON",
  100: "GMTP",
  101: "IFMP",
  102: "PNNI",
  103: "PIM",
  104: "ARIS",
  105: "SCPS",
  106: "QNX",
  107: "A/N",
  108: "IPComp",
  109: "SNP",
  11: "NVP-II",
  110: "Compaq-Peer",
  111: "IPX-in-IP",
  112: "VRRP",
  113: "PGM",
  115: "L2TP",
  116: "DDX",
  117: "IATP",
  118: "STP",
  119: "SRP",
  12: "PUP",
  120: "UTI",
  121: "SMP",
  122: "SM",
  123: "PTP",
  124: "IS-IS over IPv4",
  125: "FIRE",
  126: "CRTP",
  127: "CRUDP",
  128: "SSCOPMCE",
  129: "IPLT",
  13: "ARGUS",
  130: "SPS",
  131: "PIPE",
  132: "SCTP",
  133: "FC",
  134: "RSVP-E2E-IGNORE",
  135: "Mobility Header",
  136: "UDPLite",
  137: "MPLS-in-IP",
  138: "manet",
  139: "HIP",
  14: "EMCON",
  140: "Shim6",
  141: "WESP",
  142: "ROHC",
  143: "Ethernet",
  15: "XNET",
  16: "CHAOS",
  17: "UDP",
  18: "MUX",
  19: "DCN-MEAS",
  2: "IGMP",
  20: "HMP",
  21: "PRM",
  22: "XNS-IDP",
  23: "TRUNK-1",
  24: "TRUNK-2",
  25: "LEAF-1",
  26: "LEAF-2",
  27: "RDP",
  28: "IRTP",
  29: "ISO-TP4",
  3: "GGP",
  30: "NETBLT",
  31: "MFE-NSP",
  32: "MERIT-INP",
  33: "DCCP",
  34: "3PC",
  35: "IDPR",
  36: "XTP",
  37: "DDP",
  38: "IDPR-CMTP",
  39: "TP++",
  4: "IP-in-IP",
  40: "IL",
  41: "IPv6",
  42: "SDRP",
  43: "IPv6-Route",
  44: "IPv6-Frag",
  45: "IDRP",
  46: "RSVP",
  47: "GRE",
  48: "DSR",
  49: "BNA",
  5: "ST",
  50: "ESP",
  51: "AH",
  52: "I-NLSP",
  53: "SwIPe",
  54: "NARP",
  55: "MOBILE",
  56: "TLSP",
  57: "SKIP",
  58: "IPv6-ICMP",
  59: "IPv6-NoNxt",
  6: "TCP",
  60: "IPv6-Opts",
  62: "CFTP",
  64: "SAT-EXPAK",
  65: "KRYPTOLAN",
  66: "RVD",
  67: "IPPC",
  69: "SAT-MON",
  7: "CBT",
  70: "VISA",
  71: "IPCU",
  72: "CPNX",
  73: "CPHB",
  74: "WSN",
  75: "PVP",
  76: "BR-SAT-MON",
  77: "SUN-ND",
  78: "WB-MON",
  79: "WB-EXPAK",
  8: "EGP",
  80: "ISO-IP",
  81: "VMTP",
  82: "SECURE-VMTP",
  83: "VINES",
  84: "IPTM",
  85: "NSFNET-IGP",
  86: "DGP",
  87: "TCF",
  88: "EIGRP",
  89: "OSPF",
  9: "IGP",
  90: "Sprite-RPC",
  91: "LARP",
  92: "MTP",
  93: "AX.25",
  94: "OS",
  95: "MICP",
  96: "SCC-SP",
  97: "ETHERIP",
  98: "ENCAP",
};

export const openSupportGuidePDF = async (e) => {
  e.preventDefault();
  store.dispatch("closeMenu");
  let supportGroup = await store.getters.getCompanySupportGroup;

  // avoid making the call if we are already fetching the PDF
  if (store.getters.isFetchingSupportGuide) {
    return;
  }

  let supportGuideUrl;

  if (!store.getters.supportGuideUrl) {
    try {
      store.dispatch("setIsFetchingSupportGuide", true);

      // Fetch the pdf from the endpoint
      const { data } = await get("tdapi/support/guide", {
        headers: { Accept: "application/pdf" },
        responseType: "blob",
        params: {
          supportGroup,
        },
      });

      // create a URL for the Blob
      supportGuideUrl = URL.createObjectURL(data);

      // save blob url to store
      store.dispatch("setSupportGuideUrl", supportGuideUrl);
    } catch (error) {
      console.error("error fetching the PDF, using local fallback", error);

      // if fetch fails, use the local copy of the PDF
      supportGuideUrl = "/Speedcast Global NOC - Customer Service Guide.pdf";
    } finally {
      store.dispatch("setIsFetchingSupportGuide", false);
    }
  } else {
    supportGuideUrl = store.getters.supportGuideUrl;
  }

  // open the PDF in a new window
  const newWindow = window.open(supportGuideUrl, "_blank");

  if (newWindow) {
    setTimeout(() => {
      try {
        newWindow.document.title = "Speedcast NOC - Customer Service Guide";
      } catch (error) {}
    }, 1000);
  }
};

import { createApp, defineAsyncComponent } from "vue";
import App from "./App.vue";
import "./index.css";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/material-icons-outlined/material-icons-outlined.css";
import "@quasar/extras/fontawesome-v5/fontawesome-v5.css";
import iconSet from "quasar/icon-set/material-icons";
import "quasar/src/css/index.sass";
import { Quasar, Notify, EventBus } from "quasar";
import HostnamePlugin from "./plugins/hostname";
import { getHostname } from "./utils/hostname";
// import "./css/index.sass";
import OpenLayersMap from "vue3-openlayers";
import "vue3-openlayers/dist/vue3-openlayers.css";

import cloudWatchRum from "./cloudWatchRum";

import store from "./store";
import router from "./router/index";

import Button from "./components/reusable/Button.vue";
import i18n from "./i18n";
// import Input from "./components/reusable/Input.vue";
// import Datepicker from "./components/reusable/Datepicker.vue";
// import ToggleButton from "./components/reusable/ToggleButton.vue";
function checkCookies() {
  if (!navigator.cookieEnabled) {
    window.location.href = "/cookies.html";
  }
}
checkCookies();

const bus = new EventBus();
bus.on("logout", () => {
  router.push("/login");
});

async function adjustThemeVariables() {
  const defaultTheme = import("./css/themes/default/variables.js");
  const themeName = getHostname();

  try {
    let themeModule;
    try {
      themeModule = await import(`./css/themes/${themeName}/variables.js`);
    } catch (error) {
      console.warn(`No specific theme for ${themeName}, using default.`);
      themeModule = await defaultTheme;
    }

    const theme = themeModule.default;
    Object.entries(theme).forEach(([key, val]) => {
      document.documentElement.style.setProperty(key, val);
    });
  } catch (error) {
    console.error("Error applying theme:", error);
  }
}

async function loadTheme() {
  const hostName = getHostname();
  await import(`./css/themes/${hostName}/index.sass`).catch(() => import("./css/themes/default/index.sass"));
}

async function loadApp() {
  await adjustThemeVariables();
  await loadTheme();

  const app = createApp(App)
    .use(router)
    .use(store)
    .use(OpenLayersMap)
    .use(Quasar, {
      iconSet: iconSet,
      plugins: {
        Notify,
      },
    })
    .use(i18n)
    .component("MainButton", Button)
    .component(
      "MainInput",
      defineAsyncComponent(() => import("./components/reusable/Input.vue"))
    )
    .component(
      "MainDatepicker",
      defineAsyncComponent(() => import("./components/reusable/Datepicker.vue"))
    )
    .component(
      "MainToggleButton",
      defineAsyncComponent(() => import("./components/reusable/ToggleButton.vue"))
    )
    .use(cloudWatchRum)
    .use(HostnamePlugin);

  app.config.errorHandler = (err, _instance, info) => {
    console.error(err);
    app.config.globalProperties.cloudWatchRum("recordError", `Error: ${err.toString()}\nInfo: ${info}`);
  };

  app.mount("#app");
}

loadApp();

import { ActionTree, GetterTree, MutationTree } from "vuex";
import { get } from "../services/api";
import { setObject } from "./store_helpers";
import { rootState } from "../types/store/state";

export interface ActiveFilter {
  [key: string]: string | string[] | null;
  business_service: string | null;
  priority: string | null;
  state: string[] | null;
  search: string | null;
}

export interface Aggregate {
  priority: {
    High?: string;
    Moderate?: string;
    Normal?: string;
  };
  state: {
    New?: string;
    Open?: string;
    Resolved?: string;
  };
}

export interface Attachment {
  content: string;
  file_name: string;
  sys_id: string;
}

export interface Comment {
  comment: string;
  name: string;
  timestamp: string;
}

export interface Ticket {
  assigned_to: string;
  assignment_group: string;
  attachments: Attachment[];
  business_service: string;
  close_notes: string | null;
  comments: Comment[];
  company: string;
  created: string;
  description: string;
  incident: string;
  number: string;
  priority: string;
  service_id: string;
  status: string;
  sys_id: string;
  sys_updated_on: string;
  updated: string;
}

export interface TicketModule {
  namespaced: boolean;
  state: () => TicketState;
  getters: GetterTree<TicketState, rootState>;
  actions: ActionTree<TicketState, rootState>;
  mutations: MutationTree<TicketState>;
}

export interface TicketState {
  tickets: Ticket[];
  filteredTickets: Ticket[];
  aggregates: Aggregate;
  filteredAggregates: Aggregate;
  ticketsLoading: boolean;
  activeFilters: ActiveFilter;
  showAll: boolean;
  filterLocally: boolean;
  setAllAggregates: boolean;
  showClosedAggregates: boolean;
}

const tickets_module: TicketModule = {
  namespaced: true,
  state: () => ({
    tickets: [],
    filteredTickets: [],
    aggregates: {
      priority: {},
      state: {},
    },
    filteredAggregates: {
      priority: {},
      state: {},
    },
    ticketsLoading: false,
    activeFilters: {
      business_service: null,
      priority: null,
      state: null,
      search: null,
    },
    showAll: true,
    filterLocally: true,
    setAllAggregates: false,
    showClosedAggregates: false,
  }),

  getters: {
    tickets: (state) => {
      return state.tickets;
    },
    ticket: (state) => (number: string) => {
      return state.tickets.find((t) => t.number == number);
    },
    filteredTickets: (state) => {
      return state.filteredTickets;
    },
    ticketsLoading: (state) => {
      return state.ticketsLoading;
    },
    aggregates: (state) => {
      return state.filteredAggregates;
    },
    filters: (state) => {
      return state.activeFilters;
    },
  },
  actions: {
    getTickets({ commit }, companyid) {
      commit("updateTicketsStart");
      return get(`tdapi/cases/${companyid}`).then((data) => {
        if (data.data) {
          commit("setTickets", data.data);
          commit("setFilteredTickets", data.data);
        }
        commit("updateTicketsStop");
      });
    },
    getCaseAggregates({ commit, state }, companyid) {
      let includeclosed = state.showClosedAggregates ? "?includeclosed=true" : "";
      return get(`tdapi/caseaggregates/${companyid}${includeclosed}`).then(({ data }) => {
        if (data) {
          commit("setAggregates", data);
          commit("setFilteredAggregates", { priority: data.priority_case, state: data.state_case });
        }
      });
    },
    filterTickets({ commit, dispatch, state }, filters) {
      commit("setActiveFilters", filters);
      if (state.showAll) {
        commit("setFilteredTickets", state.tickets);
        commit("setFilteredAggregates", state.aggregates);
      } else {
        if (state.filterLocally) {
          let filteredTickets = state.tickets
            .filter((ticket) => {
              if (
                state.activeFilters.business_service &&
                ticket.business_service != state.activeFilters.business_service
              )
                return false;
              if (state.activeFilters.priority && !ticket.priority.includes(state.activeFilters.priority)) return false;
              if (state.activeFilters.state) {
                if (typeof state.activeFilters.state === "string") {
                  if (ticket.status !== state.activeFilters.state) return false;
                } else if (Array.isArray(state.activeFilters.state)) {
                  if (!state.activeFilters.state.includes(ticket.status)) return false;
                }
              }
              if (state.activeFilters.search) {
                return JSON.stringify(ticket).toLowerCase().includes(state.activeFilters.search.toLowerCase());
              }
              return true;
            })
            .sort((a, b) => new Date(b.sys_updated_on).getTime() - new Date(a.sys_updated_on).getTime());
          commit("setFilteredTickets", filteredTickets);
          dispatch("countAggregates", filteredTickets);
        } else {
          dispatch("fetchFilteredTickets");
        }
      }
    },
    fetchFilteredTickets({ commit, dispatch, state, rootState }) {
      commit("updateTicketsStart");
      let queryStr = `?q=${state.activeFilters.search || ""}&business_service=${
        state.activeFilters.business_service || ""
      }&priority=${state.activeFilters.priority || ""}&state=${state.activeFilters.state || ""}`;
      let company_id = rootState.company?.company_id;
      dispatch("fetchFilteredCaseAggregates", { company_id: company_id, queryStr: queryStr });
      get(`tdapi/cases/${company_id}/search${queryStr}`).then(({ data }) => {
        commit("setFilteredTickets", data);
        commit("updateTicketsStop");
      });
    },
    fetchFilteredCaseAggregates({ commit, state }, payload) {
      let includeclosed = state.showClosedAggregates ? "&includeclosed=true" : "";
      get(`tdapi/caseaggregates/${payload.company_id}${payload.queryStr}${includeclosed}`).then(({ data }) => {
        if (data) {
          commit("setFilteredAggregates", { priority: data.priority_case, state: data.state_case });
        }
      });
    },
    countAggregates({ commit }, tickets) {
      let priority: { [key: string]: number } = { none: 0 };
      let state: { [key: string]: number } = {};
      tickets.forEach((ticket: Ticket) => {
        if (ticket.priority) {
          if (priority[ticket.priority]) {
            priority[ticket.priority]++;
          } else {
            priority[ticket.priority] = 1;
          }
        } else {
          priority.none++;
        }
        if (state[ticket.status]) {
          state[ticket.status]++;
        } else {
          state[ticket.status] = 1;
        }
      });
      commit("setFilteredAggregates", { priority, state });
    },
    clearTickets({ commit }) {
      commit("nullTickets");
    },
  },
  mutations: {
    updateTicketsStart: (state) => {
      state.ticketsLoading = true;
    },
    updateTicketsStop: (state) => {
      state.ticketsLoading = false;
    },
    setTickets: (state, tickets) => {
      state.tickets = tickets;
      state.filterLocally = tickets.length < 100;
      setObject("tickets", tickets);
    },
    setFilteredTickets(state, tickets) {
      state.filterLocally = tickets.length < 100;
      state.filteredTickets = tickets;
    },
    setAggregates: (state, aggs) => {
      state.aggregates.priority = aggs.priority_case;
      state.aggregates.state = aggs.state_case;
    },
    setFilteredAggregates: (state, aggs) => {
      if (!state.activeFilters.priority || state.setAllAggregates) {
        state.filteredAggregates.priority = aggs.priority;
      }
      if (!state.activeFilters.state || state.setAllAggregates) {
        state.filteredAggregates.state = aggs.state;
      }
      state.setAllAggregates = false;
    },
    setActiveFilters: (state, filters) => {
      for (let f in filters) {
        //compare filters to ensure fewer filters(more data) calls api
        if (state.activeFilters[f] && filters[f] !== state.activeFilters[f]) {
          state.filterLocally = false;
        }
        //logic for telling pie charts to redraw
        if (f == "business_service" || f == "search" || (state.activeFilters[f] && !filters[f])) {
          state.setAllAggregates = true;
        }

        state.activeFilters[f] = filters[f];
      }
      state.showAll = Object.values(state.activeFilters).filter((f) => f).length == 0;
    },
    nullTickets: (state) => {
      state.tickets = [];
      localStorage.removeItem("tickets");
    },
    updateTicket: (state, ticket) => {
      for (let i = 0; i < state.tickets.length; i++) {
        if (state.tickets[i].number == ticket.number) {
          state.tickets[i] = ticket;
        }
      }
    },
    showClosedAggregates: (state, value) => {
      state.showClosedAggregates = value;
    },
  },
};
export default tickets_module;

import { ActionTree, GetterTree, MutationTree } from "vuex";
import { get } from "../services/api";
import { rootState } from "../types/store/state";

export interface Availability {
  name: string;
  data: string[];
}

export interface AvailabilityModule {
  namespaced: boolean;
  state: () => AvailabilityState;
  getters: GetterTree<AvailabilityState, rootState>;
  actions: ActionTree<AvailabilityState, rootState>;
  mutations: MutationTree<AvailabilityState>;
}

export interface AvailabilityState {
  availability: Availability[];
  outages: OutAges[];
  monthlyAvailability: {
    thisMonth: {
      raw: number | null;
      adj: number | null;
    };
    lastMonth: {
      raw: number | null;
      adj: number | null;
    };
  };
}

export interface OutAges {
  outages: string;
}

const availability_module: AvailabilityModule = {
  namespaced: true,
  state: () => ({
    availability: [],
    outages: [],
    monthlyAvailability: {
      thisMonth: {
        raw: null,
        adj: null,
      },
      lastMonth: {
        raw: null,
        adj: null,
      },
    },
  }),
  getters: {
    availability: (state) => {
      return state.availability;
    },
    monthlyAvailability: (state) => {
      return state.monthlyAvailability;
    },
    outages: (state) => {
      return state.outages;
    },
  },
  actions: {
    getAvailability({ commit }, data) {
      return new Promise((resolve, reject) => {
        const { service_id, range_type, start_date, end_date } = data;
        get(
          `tdapi/service/${service_id}/statusData?range_type=${range_type}&start_date=${start_date}&end_date=${end_date}`
        )
          .then((res) => {
            commit("updateAvailabilities", res.data);
            resolve(null);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    getMonthlyAvailability({ commit }, data) {
      return new Promise((resolve, reject) => {
        const { service_id, range_type, start_date, end_date } = data;
        get(
          `tdapi/service/${service_id}/statusData?range_type=${range_type}&start_date=${start_date}&end_date=${end_date}`
        )
          .then((res) => {
            commit("updateMonthlyAvailabilities", res.data);
            resolve(null);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },
  mutations: {
    updateAvailabilities: (state, data) => {
      if (data.availability) {
        state.availability = data.availability;
      }
      if (data.outages && data.outages.outages) {
        try {
          const formatedRows = data.outages.outages
            .filter((row: { case_number: string }) => {
              return row.case_number;
            })
            .map((element: { duration: string; type: string; reason: string }) => {
              if (Number(element.duration) > 60) {
                element.duration = Math.floor(Number(element.duration) / 60) + " hours";
              } else {
                element.duration = Math.floor(Number(element.duration)) + " minutes";
              }
              element.type = element.type[0].toUpperCase() + element.type.substring(1, element.type.length);
              element.reason = element.reason
                .split("_")
                .map((element) => {
                  return element[0].toUpperCase() + element.substring(1, element.length);
                })
                .join(" ");
              return element;
            });
          state.outages = formatedRows;
        } catch (error) {
          console.log(error);
        }
      }
    },
    updateMonthlyAvailabilities: (state, data) => {
      let now = new Date();
      data.availability.forEach((avl: { data: any[]; name: string }) => {
        avl.data.forEach((m) => {
          let date = new Date(Date.parse(m[0] + "T00:00:00Z"));
          if (avl.name.includes("RAVL")) {
            if (date.getUTCMonth() == now.getUTCMonth()) {
              state.monthlyAvailability.thisMonth.raw = m[1] == 100 ? 100 : Math.round(m[1] * 1000) / 1000;
            }
            if (date.getUTCMonth() == now.getUTCMonth() - 1) {
              state.monthlyAvailability.lastMonth.raw = m[1] == 100 ? 100 : Math.round(m[1] * 1000) / 1000;
            }
          } else {
            if (date.getUTCMonth() == now.getUTCMonth()) {
              state.monthlyAvailability.thisMonth.adj = m[1] == 100 ? 100 : Math.round(m[1] * 1000) / 1000;
            }
            if (date.getUTCMonth() == now.getUTCMonth() - 1) {
              state.monthlyAvailability.lastMonth.adj = m[1] == 100 ? 100 : Math.round(m[1] * 1000) / 1000;
            }
          }
        });
      });
    },
  },
};

export default availability_module;

const setObject = function (key: string, value: any) {
  if (!key || !value) {
    return;
  }

  if (typeof value === "object") {
    value = JSON.stringify(value);
  }
  localStorage.setItem(key, value);
};
const getObject = function <T>(key: string): T | null {
  var value = localStorage.getItem(key);

  if (value === null) {
    return null;
  }

  try {
    return JSON.parse(value) as T;
  } catch (error) {
    //Failing means it was a simple string
    return value as unknown as T;
  }
};

export { setObject, getObject };

<template>
  <q-layout view="hHh lpR fFf" class="font-lato">
    <q-header
      class="tw-bg-white dark:tw-bg-neutral-low"
      height-hint="98"
      v-if="
        loggedIn &&
        route.name !== 'Token' &&
        route.name != 'Login' &&
        route.name != 'Register' &&
        route.name != 'GuestTicket'
      "
    >
      <Banner
        v-if="company?.locked"
        :style="{ maxWidth: '100%', width: '100%', color: 'var(--dark) !important', position: 'unset' }"
      >
        <p class="tw-mb-0 tw-flex tw-items-center">
          <q-icon class="tw-pr-2" name="warning" color="dark-theme-color-2" size="2rem" />Account Locked. Please review
          the status with accounting to access this account
        </p>
      </Banner>
      <Header />
    </q-header>
    <q-page-container class="bg-neutral-color-high-light dark:tw-bg-neutral-dark">
      <q-page>
        <router-view />
      </q-page>
    </q-page-container>
    <!--    <Footer v-if="accessToken" />-->
  </q-layout>
</template>

<script lang="ts">
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { computed, onBeforeUnmount, onMounted, defineAsyncComponent, inject } from "vue";
import { useTheme } from "./utils/theme.js";
import { useI18n } from "vue-i18n";
import { CloudWatchRumFn, PageView } from "./cloudWatchRum";
import Banner from "./components/reusable/Banner.vue";

export default {
  setup() {
    const { locale } = useI18n({ useScope: "global" });
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    useTheme();
    const cloudWatchRum = inject("cloudWatchRum", ((...args) => {
      console.warn("cloudWatchRum fallback used", args);
    }) as CloudWatchRumFn);

    store.dispatch("fetchAccessToken");
    store.dispatch("fetchUsername");
    store.dispatch("preferences/loadPreferences");
    store.commit("cno_vno/dataFromLocalStorage");
    const company = computed(() => store.getters["company"]);

    const accessToken = computed(() => {
      return store.state.accessToken;
    });
    const gotoStartPage = () => store.dispatch("gotoStartPage", router);

    store.commit("setLoginState");

    const loggedIn = computed(() => store.state.loggedIn);

    const fetchInitialData = async () => {
      if (accessToken.value) {
        await store.dispatch("fetchUserData");
        await store.dispatch("fetchCompany");
        await store.dispatch("loadBanner");
      }
    };

    onMounted(() => {
      fetchInitialData();
    });

    router.beforeEach(async (to, _from) => {
      if (!accessToken.value && to.name === "ResetPassword") {
        return true;
      }
      if (!accessToken.value && to.name === "Token") {
        console.log("token");
        return true;
      }
      if (!accessToken.value && to.name == "Register") {
        console.log("Register");
        return true;
      }
      if (!accessToken.value && to.name == "GuestTicket") {
        console.log("GuestTicket");
        return true;
      }
      if (!accessToken.value && to.name == "KiboSso") {
        console.log("kibo-sso");
        return true;
      }
      if (!accessToken.value && to.name != "Login") {
        console.log("No token, Redirect to Login");
        store.dispatch("logOut", router);
        return true;
      }
      if (!accessToken.value && to.name == "Login") {
        console.log("No token, Proceed to Login");
        return true;
      }

      if (accessToken.value && (to.name === "Login" || to.name === "Token")) {
        console.log("Token set but Login or Token page, go to start page");
        if (typeof to.query?.redirect !== "undefined") {
          console.log(`Storing redirect: ${to.query.redirect}`);
          store.state.loginRedirectUrl = to.query.redirect;
        }
        gotoStartPage();
        return true;
      }
      return true;
    });

    // Remote page changes to CloudWatch RUM
    router.afterEach(async (to) => {
      const pageAttributes: Record<string, string> = {};
      //retrieve last item
      const match = to.matched[to.matched.length - 1];
      if (match?.name) {
        pageAttributes.name = match.name.toString();
      }
      if (match?.path) {
        pageAttributes.path = match.path;
      }
      const pageView: PageView = {
        pageId: to.fullPath,
        pageAttributes,
      };
      if (["/token", "/kibo-sso"].includes(pageAttributes.path)) {
        pageView.pageId = pageView.pageId.replace(/\?.*/, "");
      }
      cloudWatchRum("recordPageView", pageView);
    });

    locale.value = store.getters["preferences/language"];

    onBeforeUnmount(() => {
      store.dispatch("idirect/stopStatusUpdates");
      store.dispatch("stopUpdatingStatus");
      //store.dispatch("newtec/stopStatusUpdates");
    });
    return {
      accessToken,
      loggedIn,
      locale,
      route,
      company,
    };
  },
  components: {
    Header: defineAsyncComponent(() => import("./components/header/Header.vue")),
    Footer: defineAsyncComponent(() => import("./components/Footer.vue")),
    Banner,
  },
};
</script>

<style lang="scss">
:root {
  --main-header-height: 71px;

  @screen md {
    --main-header-height: 65px;
  }
}

body {
  margin: 0px !important;
}

:not(section)::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

:not(section)::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

:not(section)::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-image: linear-gradient(180deg, $primary-theme-color 0%, $primary-medium-theme-color 50%, #63c29c 99%);
  box-shadow: inset 2px 2px 5px 0 rgba(rgb(48, 202, 230), 0.5);
}

.q-table__middle::-webkit-scrollbar {
  height: 12px;
}

.q-table__middle::-webkit-scrollbar-corner {
  background-color: white;
}

.tw-dark .q-table__middle::-webkit-scrollbar-corner {
  background-color: #333333;
}

.q-table__middle::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

.q-table__middle::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-image: linear-gradient(90deg, $primary-theme-color 0%, $primary-medium-theme-color 50%, #63c29c 99%);
  box-shadow: inset 2px 2px 5px 0 rgba(rgb(48, 202, 230), 0.5);
}
</style>

<template>
  <q-btn
    unelevated
    no-caps
    :disable="disabled"
    class="btn"
    :class="{
      'full-width': fullWidth,
      [`btn-${variant}`]: true,
      'login-effect': effect,
    }"
  >
    <slot />
  </q-btn>
</template>

<script>
export default {
  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: "primary",
    },
    effect: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.login-effect {
  color: black !important;
  transition: all 0.3s;
  position: relative;
  background-image: linear-gradient(to right, $secondary-theme-color, $secondary-theme-color);
  overflow: hidden;
  border: none;
}
.login-effect::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(-225deg, $gradient-color-1, $gradient-color-2 51.75%, $gradient-color-2),
    linear-gradient(-225deg, $gradient-color-1, $gradient-color-2 51.75%, $gradient-color-2) !important;

  transition: all 1s;
  transform: translateX(-101%);
}

.login-effect:hover::before {
  transform: translateX(0);
}

.login-effect:hover {
  color: #fff !important;
}

.btn {
  @apply tw-flex-shrink-0;
  border-radius: 40px;
  transition: 0.3s;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.2;

  &:hover,
  &:focus {
    @apply tw-bg-primary-medium;
    color: #fff;
  }

  &.disabled,
  &:focus.disabled,
  &:hover.disabled {
    @apply tw-text-neutral-low-light tw-bg-neutral-high-light;
  }
}

button .btn-primary {
  @apply tw-bg-secondary;

  & span.q-btn__content > i,
  & span span {
    @apply dark:tw-text-black;
  }

  &:hover,
  &:focus {
    @apply tw-bg-primary-medium;
  }
}

.btn-secondary {
  @apply tw-bg-white tw-border-solid tw-border-2 tw-border-[#00a5b5] tw-text-black;

  &:hover {
    @apply tw-bg-[#00a5b5];
  }
}

.btn-negative {
  @apply tw-bg-white tw-border-solid tw-border-2 tw-border-[#C10015] tw-text-black;

  &:hover {
    @apply tw-bg-[#C10015];
  }
}

.btn-link {
  @apply tw-bg-transparent tw-text-primary-medium tw-border-none;
  text-decoration-line: underline;

  &:hover {
    @apply tw-text-primary-medium;
    background-color: transparent;
  }
}

.q-focus-helper {
  display: none;
}
</style>

import { ActionTree, GetterTree, MutationTree } from "vuex";
import { post } from "../services/api";
import { getObject, setObject } from "./store_helpers";
import { rootState } from "../types/store/state";

export interface iDirectModule {
  namespaced: boolean;
  state: () => iDirectState;
  getters: GetterTree<iDirectState, rootState>;
  actions: ActionTree<iDirectState, rootState>;
  mutations: MutationTree<iDirectState>;
}

export interface iDirectState {
  DIDs: string[];
  modems: Modem;
  statusInterval?: ReturnType<typeof setInterval>;
}

export interface Modem {
  [key: string]: any;
  NetModemName?: string | null;
  DCIR?: string | null;
  DMIR?: string | null;
  UCIR?: string | null;
  UMIR?: string | null;
  NMS_ID?: string | null;
  Antenna_Size?: string | null;
  IsMobile?: boolean | null;
  lat?: number | null;
  lon?: number | null;
}

const getModemStatuses = (state: iDirectState, didlist = {}) => {
  post("ifapi/idirectmodemsstatus", didlist).then((data) => {
    if (data.data) {
      for (var s of data.data) {
        // if modems state already set then update it, else create the object
        if (state.modems[s.DID]) {
          state.modems[s.DID].status = {
            status: s.status,
            time: s.time,
            NetModemId: s.NetModemId,
          };
          // if instances set and current instance incorrect or not set, set it
          if (
            state.modems[s.DID].instances &&
            (!state.modems[s.DID].NetModemId || state.modems[s.DID].NetModemId != s.NetModemId)
          ) {
            var curinstance = state.modems[s.DID].instances.find((m: Modem) => m.NetModemId == s.NetModemId);
          }
          state.modems[s.DID] = {
            ...state.modems[s.DID],
            ...curinstance,
          };
        } else {
          state.modems[s.DID] = {
            status: {
              status: s.status,
              time: s.time,
              NetModemId: s.NetModemId,
            },
          };
        }
      }
      setObject("idirect", { DIDs: state.DIDs, modems: state.modems });
    }
  });
};

// get the current instnace value, or the first instance if we don't know the current
const getModemVal = (state: iDirectState, key: string, DID: string) => {
  if (state.modems[DID]) {
    if (state.modems[DID][key]) {
      return state.modems[DID][key];
    }
    if (state.modems[DID].instances && state.modems[DID].instances[0] && state.modems[DID].instances[0][key]) {
      return state.modems[DID].instances[0][key];
    }
  }
  return null;
};

const idirect_module: iDirectModule = {
  namespaced: true,
  state: () => ({
    DIDs: [],
    modems: {},
    statusInterval: undefined,
  }),

  getters: {
    DIDs(state) {
      return state.DIDs;
    },
    modem: (state) => (DID: string) => {
      return state.modems[DID] || null;
    },
    UMIR: (state) => (DID: string) => {
      return getModemVal(state, "UMIR", DID);
    },
    UCIR: (state) => (DID: string) => {
      return getModemVal(state, "UCIR", DID);
    },
    DMIR: (state) => (DID: string) => {
      return getModemVal(state, "DMIR", DID);
    },
    DCIR: (state) => (DID: string) => {
      return getModemVal(state, "DCIR", DID);
    },
    NetModemName: (state) => (DID: string) => {
      return getModemVal(state, "NetModemName", DID);
    },
    modems: (state) => {
      return state.modems;
    },
  },
  actions: {
    clearIdirectModems({ commit }) {
      commit("resetModemData");
    },
    fetchIdirect({ commit }) {
      commit("updateIdirect");
    },
    setDIDList({ commit }, didlist) {
      commit("updateDIDList", didlist);
    },
    getModems({ commit }, didlist) {
      return new Promise((resolve, reject) => {
        post("myapi/idirectmodems", { dids: didlist })
          .then((data) => {
            commit("updateModems", data.data);
            resolve(null);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    startStatusUpdates({ commit }, didlist) {
      commit("beginStatusUpdates", didlist);
    },
    stopStatusUpdates({ commit }) {
      commit("endStatusUpdates");
    },
  },
  mutations: {
    resetModemData: (state) => {
      state.DIDs = [];
      state.modems = {};
      clearInterval(state.statusInterval);
      localStorage.removeItem("idirect");
    },
    updateIdirect: (state) => {
      var d = getObject<iDirectState>("idirect");
      if (!d) {
        state.DIDs = [];
        state.modems = {};
      } else {
        state.DIDs = d.DIDs || [];
        state.modems = d.modems || {};
      }
    },
    updateDIDList: (state, didlist) => {
      state.DIDs = didlist;
      setObject("idirect", { DIDs: state.DIDs, modems: state.modems });
    },
    updateModems: (state, modemlist) => {
      for (var DID of Object.keys(modemlist)) {
        if (state.modems[DID]) {
          state.modems[DID].instances = modemlist[DID];
        } else {
          state.modems[DID] = { instances: modemlist[DID] };
        }
        // set current instnace is status available
        if (!state.modems[DID].NetModemId && state.modems[DID].status && state.modems[DID].status.NetModemId) {
          var curinstance = state.modems[DID].instances.find(
            (m: Modem) => m.NetModemId == state.modems[DID].status.NetModemId
          );

          state.modems[DID] = {
            ...state.modems[DID],
            ...curinstance,
          };
        } else {
          // set some some values if we don't have a current instance
          if (state.modems[DID].instances[0]) {
            state.modems[DID].NetModemName = state.modems[DID].instances[0].NetModemName || null;
            state.modems[DID].DCIR = state.modems[DID].instances[0].DCIR || null;
            state.modems[DID].DMIR = state.modems[DID].instances[0].DMIR || null;
            state.modems[DID].UCIR = state.modems[DID].instances[0].UCIR || null;
            state.modems[DID].UMIR = state.modems[DID].instances[0].UMIR || null;
            state.modems[DID].DCIR = state.modems[DID].instances[0].DCIR || null;
            state.modems[DID].NMS_ID = state.modems[DID].instances[0].NMS_ID || null;
            state.modems[DID].Antenna_Size = state.modems[DID].instances[0].Antenna_Size || null;
            state.modems[DID].IsMobile = state.modems[DID].instances[0].IsMobile || null;
            state.modems[DID].lat = state.modems[DID].instances[0].lat || null;
            state.modems[DID].lon = state.modems[DID].instances[0].lon || null;
          }
        }
      }
      setObject("idirect", { DIDs: state.DIDs, modems: state.modems });
    },
    beginStatusUpdates: (state, didlist) => {
      getModemStatuses(state, didlist);
      state.statusInterval = setInterval(() => {
        getModemStatuses(state, didlist);
      }, 60000);
    },
    endStatusUpdates: (state) => {
      clearInterval(state.statusInterval);
    },
  },
};
export default idirect_module;

<template>
  <q-banner inline-actions :class="['banner-position text-white', bgColor]">
    <p v-if="title">
      <b>{{ title }}</b>
    </p>
    <template v-if="onClose" v-slot:action>
      <q-btn flat color="white" icon="close" class="banner-button-close" @click="onClose" />
    </template>
    <div><slot /></div>
  </q-banner>
</template>

<script>
export default {
  name: "Banner",
  props: ["onClose", "title", "bgColor"],
  setup(props) {
    const onClose = props.onClose;
    const title = props.title;
    const bgColor = props.bgColor ?? "bg-secondary-theme-color";

    return { title, bgColor, onClose };
  },
};
</script>

<style lang="scss" scoped>
.banner-button-close {
  position: absolute;
  top: 0;
  right: 10px;
  padding: 0;
}

.banner-position {
  position: absolute;
  z-index: 2;
  top: 4px;
  left: 4px;
  max-width: 350px;
  width: 100%;
}
</style>

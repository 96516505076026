import { watch, computed } from "vue";
import { useStore } from "vuex";
import { useQuasar } from "quasar";

export const useTheme = () => {
  const $q = useQuasar();
  const store = useStore();

  var darkmode = computed(() => store.getters["preferences/darkMode"]);
  $q.dark.set(darkmode.value);

  watch(
    () => darkmode.value,
    () => {
      $q.dark.set(darkmode.value);
      const parent = document.documentElement;
      if (darkmode.value) {
        parent.classList.add("tw-dark");
      } else {
        parent.classList.remove("tw-dark");
      }
    }
  );

  return {
    dark: $q.dark.isActive,
  };
};

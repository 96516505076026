import { ActionTree, GetterTree, MutationTree } from "vuex";
import { post } from "../services/api";
import { getObject, setObject } from "./store_helpers";
import { rootState } from "../types/store/state";

export interface NewtecModem {
  modems: {
    name: string;
    networkName: string;
  }[];
}

export interface NewtecModel {
  namespaced: boolean;
  state: () => NewtecState;
  getters: GetterTree<NewtecState, rootState>;
  actions: ActionTree<NewtecState, rootState>;
  mutations: MutationTree<NewtecState>;
}

export interface NewtecState {
  modemnames: string[];
  modems: NewtecModem[];
  statusInterval?: ReturnType<typeof setInterval>;
}

const newtec_module: NewtecModel = {
  namespaced: true,
  state: () => ({
    modemnames: [],
    modems: [],
    statusInterval: undefined,
  }),
  getters: {
    modemnames(state) {
      return state.modemnames;
    },
    modems(state) {
      return state.modems;
    },
    modemnetwork: (state) => (modemname: string) => {
      for (var s of state.modems) {
        for (var m of s.modems) {
          if (m.name.substring(0, m.name.lastIndexOf("-")) == modemname) {
            return m.networkName || null;
          }
        }
      }
      return null;
    },
  },
  actions: {
    clearNewtecModems({ commit }) {
      commit("resetModemData");
    },
    fetchNewtec({ commit }) {
      commit("updateNewtec");
    },
    setModemNamesList({ commit }, modemnameslist) {
      return new Promise((resolve, reject) => {
        commit("updateModemNamesList", modemnameslist);
        resolve(null);
      });
    },
    getModems({ commit }, modemnameslist) {
      return new Promise((resolve, reject) => {
        post("szapi/ship/current", modemnameslist)
          .then((data) => {
            commit("updateModems", data.data);
            resolve(null);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },
  mutations: {
    resetModemData: (state) => {
      state.modemnames = [];
      state.modems = [];
      clearInterval(state.statusInterval);
      localStorage.removeItem("newtec");
    },
    updateNewtec: (state) => {
      var d = getObject<NewtecState>("newtec");
      if (!d) {
        state.modemnames = [];
        state.modems = [];
      } else {
        state.modemnames = d.modemnames || [];
        state.modems = d.modems || [];
      }
    },
    updateModemNamesList: (state, modemnameslist) => {
      // remove serial number from end of name
      state.modemnames = modemnameslist.map((m: string) => m.substring(0, m.lastIndexOf("-")));
      setObject("newtec", {
        modemnames: state.modemnames,
        modems: state.modems,
      });
    },
    updateModems: (state, modemlist) => {
      state.modems = modemlist;
      setObject("newtec", {
        modemnames: state.modemnames,
        modems: state.modems,
      });
    },
  },
};
export default newtec_module;

import { ActionTree, GetterTree, MutationTree } from "vuex";
import { get, post, put } from "../services/api";
import { rootState } from "../types/store/state";

export interface AdminModule {
  namespaced: boolean;
  state: () => AdminState;
  getters: GetterTree<AdminState, rootState>;
  actions: ActionTree<AdminState, rootState>;
  mutations: MutationTree<AdminState>;
}

export interface AdminState {
  defaultTableHeaders: TableHeaders[];
  defaultTableHeadersAlerts: TableHeaders[];
  users: User[];
  usersAlerts: User[];
  submitError: string | null;
  availableCompanies: AvailableCompanies[];
  availableRoles: AvailableRoles[];
}

export interface AvailableCompanies {
  sys_id: string;
  name: string;
}

export interface AvailableRoles {
  sys_id: string;
  name: string;
}

export interface GrantedRoles {
  name: string;
  sys_id: string;
}

export interface ContactRelationships {
  company_id: string;
  company_name: string;
  service_level: string;
}
export interface User {
  [key: string]: any;
  customerNumber: string;
  userId: string;
  emailAddress: string;
  username: string;
  sys_id: string;
  isAlertUser: boolean;
  grantedRoles: GrantedRoles[];
}

export interface NewUser {
  [key: string]: any;
  mailAddress: string;
  first_name: string;
  last_name: string;
  contactRelationships: ContactRelationships[];
  grantedRoles: GrantedRoles[];
}

export interface TableHeaders {
  id: string;
  role: string;
}

const handleRemoveRoles = (roles: string[], user: User, defaultCompasRoles: TableHeaders[]) => {
  const snowRolesUrl = `tdapi/users/${user.sys_id}/roles`;
  const alertRolesUrl = `itapi/starlink/${user.username}/alerts`;
  const request = [];
  const snowRoles = roles.filter((role) => role.includes("compass_"));
  const alertRoles = roles.filter((role) => !role.includes("compass_"));
  if (snowRoles.length > 0) {
    const idSnowRoles = defaultCompasRoles.filter((element) => snowRoles.includes(element.role));
    request.push(put(snowRolesUrl, { roles: idSnowRoles }));
  }
  if (alertRoles.length > 0) {
    request.push(put(alertRolesUrl, { type: alertRoles }));
  }
  return request;
};
const handleAddRoles = (roles: string[], user: User, defaultCompasRoles: TableHeaders[]) => {
  const snowRolesUrl = `tdapi/users/${user.sys_id}/roles`;
  const alertRolesUrl = `itapi/starlink/${user.username}/alerts`;
  const request = [];
  const snowRoles = roles.filter((role) => role.includes("compass_"));
  const alertRoles = roles.filter((role) => !role.includes("compass_"));
  if (snowRoles.length > 0) {
    const idSnowRoles = defaultCompasRoles.filter((element) => snowRoles.includes(element.role));
    request.push(post(snowRolesUrl, { roles: idSnowRoles }));
  }
  if (alertRoles.length > 0) {
    request.push(post(alertRolesUrl, { type: alertRoles }));
  }
  return request;
};
const admin_module: AdminModule = {
  namespaced: true,
  state: () => ({
    availableCompanies: [],
    availableRoles: [],
    defaultTableHeaders: [],
    defaultTableHeadersAlerts: [],
    users: [],
    usersAlerts: [],
    submitError: null,
  }),
  getters: {
    availableCompanies: (state) => {
      return state.availableCompanies;
    },
    availableRoles: (state) => {
      return state.availableRoles;
    },
    defaultTableHeaders: (state) => {
      return state.defaultTableHeaders;
    },
    defaultTableHeadersAlerts: (state) => {
      return state.defaultTableHeadersAlerts;
    },
    users: (state) => {
      return state.users;
    },
    usersAlerts: (state) => {
      return state.usersAlerts;
    },
  },
  actions: {
    getDataForNewUsers({ commit }, companyId) {
      get(`tdapi/companies/${companyId}/child-companies`)
        .then((res) => {
          commit("updateAvailableCompanies", res.data);
        })
        .catch((e) => {
          console.error(e);
        });

      get(`tdapi/roles/${companyId}/available`)
        .then((res) => {
          commit("updateAvailableRoles", res.data);
        })
        .catch((e) => {
          console.error(e);
        });
    },
    getDataForRolesTable({ commit }, companyId) {
      return get(`tdapi/companies/${companyId}/user-permissions`)
        .then((res) => {
          commit("updateRolesTable", res.data.data);
        })
        .catch((e) => {
          throw new Error(e);
        });
    },
    getDataForAlertsTable({ commit }, companyId) {
      return Promise.allSettled([get(`itapi/starlink/${companyId}/alerts`), get(`itapi/starlink/alerts/all`)])
        .then((res) => {
          commit("updateAlertsTable", res);
        })
        .catch((e) => {
          throw new Error(e);
        });
    },
    saveUpdateAlerts({ state }, data) {
      const sendingModifications = [];
      for (const record of data) {
        const userNoEdited = state.usersAlerts.filter((user) => user.username.includes(record.username));
        const rolesOfRecord = Object.entries(record.roles);
        if (userNoEdited.length !== 0) {
          const newRoles = rolesOfRecord.filter(
            (element) => !userNoEdited[0].grantedRoles.some((role) => role.name === element[0]) && element[1]
          );

          const removeRoles = userNoEdited[0].grantedRoles
            .filter((element) => !record.roles[element.name] && element)
            .map((role) => role.name);

          if (removeRoles.length > 0) {
            sendingModifications.push(...handleRemoveRoles(removeRoles, userNoEdited[0], []));
          }
          if (newRoles.length > 0) {
            const formatedRoleArray = newRoles.map((element) => element[0]);
            sendingModifications.push(...handleAddRoles(formatedRoleArray, userNoEdited[0], []));
          }
        } else if (record.isNewUser) {
          const rolesToAdd = rolesOfRecord.filter((element) => element[1]).map((element) => element[0]);
          sendingModifications.push(...handleAddRoles(rolesToAdd, record, []));
        }
      }

      return Promise.allSettled(sendingModifications)
        .then((res) => {
          for (const request of res) {
            if (request.status !== "fulfilled") {
              throw new Error();
            }
          }
        })
        .catch((e) => {
          throw new Error(e);
        });
    },
    saveUpdateRoles({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        const sendingModifications = [];
        const compasRoles = state.defaultTableHeaders.filter((element) => element.role.includes("compass_"));
        for (const record of data) {
          const userNoEdited = state.users.filter((user) => user.username.includes(record.username));
          const rolesOfRecord = Object.entries(record.roles);
          if (userNoEdited.length !== 0) {
            const newRoles = rolesOfRecord.filter(
              (element) => !userNoEdited[0].grantedRoles.some((role) => role.name === element[0]) && element[1]
            );

            const removeRoles = userNoEdited[0].grantedRoles
              .filter((element) => !record.roles[element.name] && element)
              .map((role) => role.name);
            if (removeRoles.length > 0) {
              sendingModifications.push(...handleRemoveRoles(removeRoles, userNoEdited[0], compasRoles));
            }
            if (newRoles.length > 0) {
              const formatedRoleArray = newRoles.map((element) => element[0]);
              sendingModifications.push(...handleAddRoles(formatedRoleArray, userNoEdited[0], compasRoles));
            }
          } else if (record.isNewUser) {
            const rolesToAdd = rolesOfRecord.filter((element) => element[1]).map((element) => element[0]);
            sendingModifications.push(...handleAddRoles(rolesToAdd, record, compasRoles));
          }
        }
        Promise.allSettled(sendingModifications)
          .then((res) => {
            for (const request of res) {
              if (request.status !== "fulfilled") {
                throw new Error();
              }
            }
            commit("updateUsers", data);
            resolve(null);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    createUser({ rootGetters }, payload) {
      return post(`tdapi/companies/${rootGetters["company_id"]}/users`, payload).catch((e) => {
        const message = e.response?.data.message || "Couldn't add user, please try again later";
        throw new Error(message);
      });
    },
  },
  mutations: {
    updateAvailableCompanies: (state, data) => {
      state.availableCompanies = data.companies;
    },
    updateAvailableRoles: (state, data) => {
      state.availableRoles = data;
    },
    updateAlertsTable: (state, data) => {
      if (data[0].status !== "fulfilled" || data[1].status !== "fulfilled") {
        throw new Error();
      }
      const userAlerts = data[0].value;
      const allAlerts = data[1].value;
      let employees: User[] = [];

      const alerts = allAlerts.data.map((element: string) => {
        return { role: element, id: element };
      });
      for (const user of userAlerts.data) {
        let existUser = employees.filter((employee) => employee.username?.includes(user.emailAddress));
        if (existUser.length > 0) {
          if (user.isActive) {
            existUser[0].grantedRoles.push({ name: user.type, sys_id: user.type });
          }
        } else {
          const newUser: User = {
            customerNumber: user.customerNumber,
            userId: user.userId,
            emailAddress: user.emailAddress,
            username: user.emailAddress,
            sys_id: user.userId,
            isAlertUser: true,
            grantedRoles: [],
          };
          if (user.isActive) {
            newUser.grantedRoles.push({ name: user.type, sys_id: user.type });
          }
          employees.push(newUser);
        }
      }
      state.defaultTableHeadersAlerts = alerts;
      state.usersAlerts = employees;
    },
    updateRolesTable: (state, data) => {
      const { roleList, employees }: { roleList: TableHeaders[]; employees: User[] } = data;
      if (typeof roleList === "string" || roleList.length === 0 || employees.length === 0) {
        throw new Error();
      }

      state.defaultTableHeaders = roleList;
      state.users = employees;
    },
    updateUsers: (state, data) => {
      for (const record of data) {
        const userNoEdited = state.users.filter((user) => user.username.includes(record.username));
        if (userNoEdited.length > 0) {
          userNoEdited[0].roles = Object.entries(record.roles)
            .filter((element) => element[1])
            .map((element) => element[0]);
        } else {
          record.roles = Object.entries(record.roles)
            .filter((element) => element[1])
            .map((element) => element[0]);
          state.users.push(record);
        }
      }
    },
  },
};

export default admin_module;

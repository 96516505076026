import httpClient from "./httpClient";
import { AxiosRequestConfig } from "axios";

export const get = (api: string, config?: AxiosRequestConfig) => {
  return httpClient.get(`${import.meta.env.VITE_API_URL}/${api}`, config);
};

export const post = (api: string, data?: Record<string, unknown>, config?: AxiosRequestConfig) => {
  return httpClient.post(`${import.meta.env.VITE_API_URL}/${api}`, data, config);
};

export const put = (api: string, data?: Record<string, unknown>, config?: AxiosRequestConfig) => {
  return httpClient.put(`${import.meta.env.VITE_API_URL}/${api}`, data, config);
};

export const patch = (api: string, data?: Record<string, unknown>, config?: AxiosRequestConfig) => {
  return httpClient.patch(`${import.meta.env.VITE_API_URL}/${api}`, data, config);
};

export const Delete = (api: string, config?: AxiosRequestConfig) => {
  return httpClient.delete(`${import.meta.env.VITE_API_URL}/${api}`, config);
};
